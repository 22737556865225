import dayjs from '../helpers/date-helpers';

export enum FieldType {
    EMAIL = 'email',
    NUMBER = 'number',
    DATE = 'date',
    DATE_STRING = 'date-string',
    PASSWORD = 'password',
    TEXT = 'text',
    COUNTRY = 'country',
    CHECKBOX = 'checkbox',
    HIDDEN = 'hidden',
    FILES = 'files',
}

type formObject = { [name: string]: FieldObject };
type requiredFn = (FieldObject, formObject) => boolean

interface FieldObject {
    type: FieldType;
    value?: any;
    placeholder?: string;
    errorMessage?: string;
    disabled?: boolean;
    readonly?: boolean;
    validationRules?: {
        min?: number;
        max?: number;
        minLength?: number;
        maxLength?: number;
        required?: boolean | requiredFn;
        pattern?: string;
        method?: Function;
    };
    customErrorMessage?: {
        min?: string;
        max?: string;
        minLength?: string;
        maxLength?: string;
        required?: string;
        pattern?: string;
        email?: string;
        method?: string;
    };
}

export class FormController {
    public formObject: { [name: string]: FieldObject };
    public isValid: boolean;

    constructor(formSetup: { [name: string]: FieldObject }) {
        this.initFormObject(formSetup);
    }

    public handleChange(fieldKey: string, value: any): FormController {

        const fieldObject = this.formObject[fieldKey];

        fieldObject.value = value;

        if (fieldObject.errorMessage) {
            fieldObject.errorMessage = null;
        }


        return this.copy();
    }

    public handleChanges(changes: Array<{ fieldKey: string; value: any }>): FormController {
        changes.forEach(({ fieldKey, value }) => {
            const fieldObject = this.formObject[fieldKey];

            fieldObject.value = value;

            if (fieldObject.errorMessage) {
                fieldObject.errorMessage = null;
            }
        });

        return this.copy();
    }

    public getFormValues(): { [name: string]: any } {
        return Object.keys(this.formObject).reduce((finalValue, fieldKey) => {
            const { type, value } = this.formObject[fieldKey];
            let parsedValue = value;

            switch (type) {
                case FieldType.COUNTRY:
                    parsedValue = value.value;
                    break;
                case FieldType.NUMBER:
                    parsedValue = value ? parseFloat(value) : value;
                    break;
                case FieldType.DATE_STRING:
                    parsedValue = dayjs(value)
                        .utc()
                        .toDate();
                    break;
            }

            finalValue = {
                ...finalValue,
                [fieldKey]: parsedValue,
            };

            return finalValue;
        }, {});
    }

    public validate = (): FormController => {
        this.isValid = true;

        this.formObject = Object.keys(this.formObject).reduce((finalValue, fieldKey) => {
            const fieldObject = this.formObject[fieldKey];
            const validatedField = this.validateField(fieldObject, fieldKey);

            finalValue = {
                ...finalValue,
                [fieldKey]: validatedField,
            };

            if (validatedField.errorMessage) {
                this.isValid = false;
            }

            return finalValue;
        }, {});

        return this.copy();
    };

    public copy(): FormController {
        const copiedFormController = new FormController(this.formObject);
        copiedFormController.isValid = this.isValid;
        return copiedFormController;
    }

    private initFormObject(formSetup: { [name: string]: FieldObject }) {
        this.formObject = Object.keys(formSetup).reduce((finalValue, fieldKey) => {
            const fieldObject = formSetup[fieldKey];
            const { value, placeholder, validationRules, customErrorMessage } = fieldObject;

            finalValue = {
                ...finalValue,
                [fieldKey]: {
                    ...fieldObject,
                    value: 'value' in fieldObject ? value : null,
                    placeholder: 'placeholder' in fieldObject ? placeholder : '',
                    validationRules: 'validationRules' in fieldObject ? validationRules : {},
                    customErrorMessage:
                        'customErrorMessage' in fieldObject ? customErrorMessage : {},
                },
            };

            return finalValue;
        }, {});
    }

    private validateField = (fieldObject: FieldObject, key: string): FieldObject => {
        const { value, validationRules, customErrorMessage, type } = fieldObject;
        const { min, max, minLength, maxLength, required, pattern, method } = validationRules;

        if (typeof required === 'function') {
            if (required(fieldObject, this.formObject) && !value) {
                fieldObject.errorMessage = customErrorMessage.required
                    ? customErrorMessage.required
                    : 'This field is required';
            } else {
                fieldObject.errorMessage = null;
            }
        }
        else if (required && !requiredValidator(value)) {
            fieldObject.errorMessage = customErrorMessage.required
                ? customErrorMessage.required
                : 'This field is required';
        } else if (type === FieldType.EMAIL && !emailValidator(value)) {
            fieldObject.errorMessage = customErrorMessage.required
                ? customErrorMessage.required
                : 'This is not a valid email';
        } else if (min && !minValidator(value, min, type)) {
            fieldObject.errorMessage = customErrorMessage.min
                ? customErrorMessage.min
                : `Value must be greater than or equal to ${min}`;
        } else if (max && !maxValidator(value, max, type)) {
            fieldObject.errorMessage = customErrorMessage.max
                ? customErrorMessage.max
                : `Value must be less than or equal to ${max}`;
        } else if (minLength && !minLengthValidator(value, minLength)) {
            fieldObject.errorMessage = customErrorMessage.minLength
                ? customErrorMessage.minLength
                : `Value mustn't have less than ${min} characters`;
        } else if (maxLength && !maxLengthValidator(value, maxLength)) {
            fieldObject.errorMessage = customErrorMessage.maxLength
                ? customErrorMessage.maxLength
                : `Value mustn't have more than ${max} characters`;
        } else if (pattern && !patternValidator(value, pattern)) {
            fieldObject.errorMessage = customErrorMessage.pattern ? customErrorMessage.pattern : '';
        } else if (method && !method(value)) {
            fieldObject.errorMessage = customErrorMessage.method ? customErrorMessage.method : '';
        } else {
            fieldObject.errorMessage = null;
        }

        return fieldObject;
    };
}

// Validator functions

const minValidator = (
    value: number | Date | Object,
    min: number | string,
    fieldType: FieldType
): boolean => {
    if (value instanceof Date) {
        return dayjs(value).isSameOrAfter(min);
    } else if (fieldType === FieldType.FILES) {
        return Object.keys(value).length >= min;
    } else if (typeof value === 'string') {
        return parseFloat(value) >= min;
    }

    return false;
};

const maxValidator = (
    value: number | Date | Object,
    max: number | string,
    fieldType: FieldType
): boolean => {
    if (value instanceof Date) {
        return dayjs(value).isSameOrBefore(max);
    } else if (fieldType === FieldType.FILES) {
        return Object.keys(value).length <= max;
    } else if (typeof value === 'string') {
        return parseFloat(value) <= max;
    }

    return false;
};

const minLengthValidator = (value: string, minLength: number): boolean => {
    return value.length >= minLength;
};

const maxLengthValidator = (value: string, maxLength: number): boolean => {
    return value.length <= maxLength;
};

export const requiredValidator = (value: any): boolean => {
    switch (typeof value) {
        case 'undefined':
            return false;
        case 'string':
            return value.trim() !== '';
        case 'number':
            return value.toString().trim() !== '';
        case 'boolean':
            return value !== false;
        case 'object':
            return (
                (Array.isArray(value) && value.length > 0) ||
                (value instanceof Date && dayjs(value).isValid()) ||
                (value !== null && Object.keys(value).length > 0)
            );
        default:
            return false;
    }
};

const patternValidator = (value: string, pattern: string): boolean => {
    const reg = RegExp(pattern);
    return reg.test(String(value));
};

const emailValidator = (value: string): boolean => {
    const atSplit = value.split('@');

    if (atSplit.length === 2) {
        const dotSplit = atSplit[1].split('.');
        const lastStringFromSplit = dotSplit[dotSplit.length - 1];

        if (dotSplit.length > 1 && lastStringFromSplit.length > 1) {
            return true;
        }
    }

    return false;
};
