import css from 'styled-jsx/css';
import { colors } from './global';

export default css`
    .content {
        display: flex;
        justify-content: center;
        background-color: ${colors.backgroundGrey};
        min-height: 100vh;

        & > div {
            width: 100%;
            max-width: 510px;
            margin: 0 auto;
            padding: 40px 24px 48px;
            box-sizing: border-box;
            align-self: center;

            & > div {
                padding: 40px;
                background-color: #fff;
                margin-top: 32px;
                border-radius: 3px;

                &.page-loader {
                    text-align: center;
                }
            }
        }
    }

    h1 {
        font-size: 20px;
        line-height: 32px;
        margin: 0 0 40px;
    }

    .no-margin {
        margin-bottom: 0;
    }

    input[type='submit'] {
        margin-top: 40px;
        width: 100%;
        box-shadow: none;
    }

    .fp-link {
        font-size: 14px;
        color: #263238;
        text-decoration: none;
        display: block;
        text-align: right;
        opacity: 0.5;
        will-change: opacity;

        &:hover {
            opacity: 1;
        }

        & + input[type='submit'] {
            margin-top: 16px;
        }
    }

    .small-grey-link {
        position: absolute;
        top: 16px;
        left: 16px;
        display: inline-block;
        font-size: 12px;
        font-weight: 700;
        line-height: 20px;
        height: 20px;
        padding: 0 10px;
        text-decoration: none;
        border-radius: 10px;
        color: rgba(38, 50, 56, 0.6);
        background-color: rgba(38, 50, 56, 0.1);

        & > svg {
            fill: rgba(38, 50, 56, 0.7);
            margin: 0 2px -1px 0;
        }
    }

    .logo {
        display: block;
        margin: 20px auto 0;
        max-width: 90%;
    }

    .account-expired {
        background-color: hsl(49, 98%, 90%);
        color: hsl(49, 98%, 20%);
        border-radius: 3px;
        font-size: 16px;
        padding: 8px 12px;
        margin-top: 16px;

        & + form > :global(label) {
            margin-top: 16px;
        }
    }

    @media (min-width: 600px) {
        .content > div > div {
            padding: 40px 48px;
        }
    }

    @media (min-width: 800px) {
        .content {
            & > div {
                padding: 40px 32px 48px;
            }
        }
    }
`;
