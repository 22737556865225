const SvgLoader = ({ size, color }: { size?: number; color?: string }) => (
    <svg
        className="loading-spinner"
        style={{ width: `${size || 20}px`, height: `${size || 20}px` }}
        viewBox="0 0 12 12"
    >
        <circle
            cx="50%"
            cy="50%"
            r="5"
            style={{ strokeDasharray: '31.4159px', strokeWidth: '10%' }}
        />
        <style jsx>{`
            .loading-spinner {
                animation: spin-loader 2s linear infinite;
                transform-origin: center;
                overflow: visible;
                will-change: transform;

                & > circle {
                    stroke: ${color || '#fff'};
                    fill: transparent;
                    transform-origin: center;
                    transition: stroke-dashoffset 225ms linear;
                    animation: change-loader-length 4s cubic-bezier(0.35, 0, 0.25, 1) infinite;
                    transition-property: stroke;
                }
            }

            @keyframes spin-loader {
                from {
                    transform: rotate(-90deg);
                }
                to {
                    transform: rotate(270deg);
                }
            }

            @keyframes change-loader-length {
                0% {
                    stroke-dashoffset: 29.8451;
                    transform: rotate(0deg);
                }
                12.5% {
                    stroke-dashoffset: 6.28319;
                    transform: rotate(0deg);
                }
                12.5001% {
                    stroke-dashoffset: 6.28319;
                    transform: rotateX(180deg) rotate(72.5deg);
                }
                25% {
                    stroke-dashoffset: 29.8451;
                    transform: rotateX(180deg) rotate(72.5deg);
                }
                25.0001% {
                    stroke-dashoffset: 29.8451;
                    transform: rotate(270deg);
                }
                37.5% {
                    stroke-dashoffset: 6.28319;
                    transform: rotate(270deg);
                }
                37.5001% {
                    stroke-dashoffset: 6.28319;
                    transform: rotateX(180deg) rotate(161.5deg);
                }
                50% {
                    stroke-dashoffset: 29.8451;
                    transform: rotateX(180deg) rotate(161.5deg);
                }
                50.0001% {
                    stroke-dashoffset: 29.8451;
                    transform: rotate(180deg);
                }
                62.5% {
                    stroke-dashoffset: 6.28319;
                    transform: rotate(180deg);
                }
                62.5001% {
                    stroke-dashoffset: 6.28319;
                    transform: rotateX(180deg) rotate(251.5deg);
                }
                75% {
                    stroke-dashoffset: 29.8451;
                    transform: rotateX(180deg) rotate(251.5deg);
                }
                75.0001% {
                    stroke-dashoffset: 29.8451;
                    transform: rotate(90deg);
                }
                87.5% {
                    stroke-dashoffset: 6.28319;
                    transform: rotate(90deg);
                }
                87.5001% {
                    stroke-dashoffset: 6.28319;
                    transform: rotateX(180deg) rotate(341.5deg);
                }
                100% {
                    stroke-dashoffset: 29.8451;
                    transform: rotateX(180deg) rotate(341.5deg);
                }
            }
        `}</style>
    </svg>
);

export default SvgLoader;
